<template>
  <div class="staticQrCodePay">
    <div>
      <p>支付金额</p>
      <div class="input-box">
        <svg
          t="1718687212048"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="11140"
          width="40"
          height="40"
        >
          <path
            d="M1009.9145991-245.15229294C595.14720475-502.64645218 50.034491-372.64338094-208.08604943 45.21071409c-20.99990054 33.9020525-39.02287572 68.76627774-54.58553325 104.12773184l-40.79224045-121.13041921c-8.2591859-24.53862826-34.61238069-37.57638922-59.04768848-29.25908466-24.25449696 8.49811357-37.48598382 35.03857762-29.10410395 59.6288661l72.90555654 217.43807708c0 0 0.18081078 0.19372586 0.14206563 0.30996138 4.25551431 12.50178516 13.01193085 21.6779402 24.04139758 27.09581152 10.7517939 5.45661651 23.40855975 6.74166567 35.92326 2.49906645l0.0387452-0.11623553 216.67608864-74.31329787c0.23247104 0.08394877 0.23247104 0.08394877 0.27121622-0.0258301 24.47405296-8.42708166 37.70553979-34.96108726 29.3817786-59.39639506-8.26564254-24.5515433-34.61883911-37.57638922-59.05414692-29.27199973l-117.75312881 40.23689296c13.94181503-30.22771581 29.93712608-59.83550879 47.81158078-89.01064468 233.78855312-378.29524355 727.41534938-496.06774402 1102.99197051-262.8991155 375.43455547 233.24612066 490.54655221 728.95869969 256.91298162 1107.17645288-233.543167 378.37273389-727.44763791 496.1968946-1102.87573499 262.9443173C-21.10170079 1116.23143594-122.14918857 996.12776478-185.41364799 860.03524098l-0.27121621 0.05166023c-3.77765717-9.92523018-11.46858051-18.21024442-22.28494972-22.13642566-19.68256281-7.16140418-41.68338126 3.16419105-48.97393605 23.19546222-2.49906643 6.88373131-2.52489653 13.99993369-1.2785925 20.69639577 1.2527624 2.87360309 1.03320463 5.22414545 2.05995264 8.00734136 0.27767469 0.61992278 0.61346615 1.12361007 0.89113902 1.73061957 69.46369087 150.17640626 180.02958359 282.69145542 330.96797832 376.37735648 414.69636066 257.73308871 959.95114004 127.63961203 1217.96190337-290.25968481C1551.63064903 559.92781631 1424.63678988 12.47101684 1009.9145991-245.15229294L1009.9145991-245.15229294zM1009.9145991-245.15229294"
            p-id="11141"
          ></path>
          <path
            d="M265.1091106 629.70146857l5e-8 0.23892948c-22.16225577 0-40.35958689 18.19732937-40.35958695 40.34667184 0 22.16225577 18.20378779 40.35958689 40.35958695 40.35958692l0 0.23892947 242.62891662 0 0 239.50992741 0.1162355 0c0 22.16225577 18.07463721 40.23043455 40.35958693 40.23043457 22.27203467 0 40.23043455-18.07463721 40.23043456-40.23043457l0.35516498 0 0-239.50992741 242.51268109 0 0-0.47785717c22.27203467 0 40.23043455-18.08755227 40.23043456-40.34667182 0-22.40118524-17.95840166-40.35958689-40.23043456-40.35958692l-242.51268109 0 0-124.55291136 242.51268109 0 0-0.48431559c22.27203467 0 40.23043455-18.08755227 40.23043456-40.35958689 0-22.27849131-17.95840166-40.35312848-40.23043456-40.35312849l-242.51268109 0 0-74.24872259 240.47855857-235.91953948-0.25184454-0.23892947c16.64752237-14.72963538 18.21024442-40.23689297 3.24168135-57.00710753-14.72963538-16.75729949-40.2433496-18.19732937-56.88441534-3.35146024l-226.10408817 222.97864058-237.95366205-233.74980796c-16.77021456-14.74255042-42.15477998-13.17982657-57.00065086 3.58393131-14.736092 16.53128687-13.17982657 42.03208783 3.46769577 56.76172141l-0.24538611 0.36808003 250.1713159 244.65658254 0 76.1666114-242.62245818 0 0 0.23247104c-22.16225577 0-40.2433496 17.96485831-40.24334961 40.23689295 0 22.40118524 18.08755227 40.36604358 40.24334961 40.36604355l0 0.23247105 242.6289166 0 0 124.68206194L265.1091106 629.70146857 265.1091106 629.70146857zM265.1091106 629.70146857"
            p-id="11142"
          ></path>
        </svg>
        <input
          ref="inputElementRef"
          type="text"
          @focus="show = true"
          v-model="obj.amount"
        />
      </div>
    </div>
    <Keyboard
      v-if="show"
      @close="show = false"
      :inputDom="inputElementRef"
      :obj="obj"
    />
  </div>
</template>

<script>
import Keyboard from "@/components/Keyboard";
export default {
  components: { Keyboard },
  data() {
    return {
      show: true,
      inputElementRef: null,
      obj: { amount: "", staticCodeId: "", channel: "" },
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.inputElementRef = this.$refs.inputElementRef;
    });
    this.getUrlCode();
  },
  methods: {
    // 获取路径参数
    getUrlCode() {
      let geturl = window.location.href;
      let getqyinfo = geturl.split("?")[1];
      let getqys = getqyinfo.split("&");
      let obj = {}; //创建空对象，接收截取的参数
      for (let i = 0; i < getqys.length; i++) {
        console.log(i);
        let item = getqys[i].split("=");
        let key = item[0];
        let value = item[1];
        obj[key] = value;
      }
      this.obj.staticCodeId = obj.staticCodeId;
      this.obj.channel = obj.channel;
    },
  },
};
</script>

<style lang="less" scoped>
.staticQrCodePay {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  padding: 30px;
  .input-box {
    display: flex;
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #dddddd;
    padding: 20px 0;
    input {
      height: 50px;
      width: 100%;
      display: block;
      font-size: 40px;
      font-weight: 600;
      margin-left: 20px;
    }
  }
}
</style>
