<template>
  <div
    class="keyboard"
    ref="keyboard"
    :style="{ left: moveX + 'px', bottom: moveY + 'px' }"
  >
    <!-- <div class="drag" @mousedown="keyDown">
        <p></p>
        <div @click="$emit('close')" @mousedown.stop @mousemove.stop>
        </div>
      </div> -->
    <div class="main">
      <div class="right">
        <div class="left">
          <div
            class="item"
            v-for="i in symbols"
            :key="i"
            @click="insertTxtAndSetcursor(i)"
          >
            {{ i }}
          </div>
          <div
            class="item"
            style="width: 65.5%"
            @click="insertTxtAndSetcursor('0')"
          >
            0
          </div>
          <div
            class="item"
            @click="insertTxtAndSetcursor('.')"
            style="font-size: 20px; font-weight: 600"
          >
            ·
          </div>
        </div>
        <div class="right">
          <div class="item" @click="insertTxtAndSetcursor('', true)">
            <svg
              t="1718604182639"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3223"
              width="30"
              height="30"
            >
              <path
                d="M269.653333 212.352A36.565333 36.565333 0 0 1 298.666667 198.101333h640c20.181333 0 36.565333 16.384 36.565333 36.565334v554.666666a36.565333 36.565333 0 0 1-36.565333 36.565334H298.666667a36.565333 36.565333 0 0 1-29.013334-14.250667l-213.333333-277.333333a36.565333 36.565333 0 0 1 0-44.629334l213.333333-277.333333z m47.018667 58.88L131.498667 512l185.173333 240.768h585.386667V271.232H316.714667z"
                fill="#262626"
                p-id="3224"
              ></path>
              <path
                d="M635.52 644.522667l-213.333333-213.333334 51.712-51.712 213.333333 213.333334-51.712 51.712z"
                fill="#262626"
                p-id="3225"
              ></path>
              <path
                d="M422.186667 592.810667l213.333333-213.333334 51.712 51.712-213.333333 213.333334-51.712-51.712z"
                fill="#262626"
                p-id="3226"
              ></path>
            </svg>
          </div>
          <div
            class="item"
            @click="confirm()"
            style="height: 74%; background-color: #05c160; color: #fff"
          >
            确认
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      symbols: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
      flag: false,
      seto: null,
      downInfo: {},
      moveX: 0,
      moveY: 0,
      data: {},
      inputDomInfo: {},
    };
  },
  props: {
    inputDom: {
      type: Node,
      default: null,
    },
    obj: { type: Object, default: null },
  },
  mounted() {
    this.data = this.$props.obj;
    document.addEventListener("mousemove", this.keyMove);
  },
  watch: {
    inputDom: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.inputDomInfo = newVal;
          // 在这里可以执行需要使用 inputDom 的逻辑
          console.log("子组件中的 inputDom:", this.inputDomInfo);
        }
      },
    },
  },
  methods: {
    // 输入文本
    insertTxtAndSetcursor(text, del) {
      let element = this.inputDomInfo;
      if (element) {
        let startPos = element.selectionStart;
        // 获取光标开始的位置
        let endPos = element.selectionEnd; // 获取光标结束的位置
        if (startPos === undefined || endPos === undefined) return; // 如果没有光标位置 不操作
        let oldTxt = element.value; // 获取输入框的文本内容
        let result = "";
        // 光标位置不能小于0
        const num = startPos - 1;
        if (del && num >= 0) {
          result = oldTxt.substring(0, startPos - 1) + oldTxt.substring(endPos); // 将文本插入
        } else {
          result =
            oldTxt.substring(0, startPos) + text + oldTxt.substring(endPos); // 将文本插入
        }
        element.value = result; // 将拼接好的文本设置为输入框的值
        element.focus(); // 重新聚焦输入框
        if (del && num >= 0) {
          element.selectionStart = startPos - 1 + (text + "").length; // 设置光标开始的位置
          element.selectionEnd = startPos - 1 + (text + "").length; // 设置光标结束的位置
        } else {
          element.selectionStart = startPos + (text + "").length; // 设置光标开始的位置
          element.selectionEnd = startPos + (text + "").length; // 设置光标结束的位置
        }
        this.data.amount = element.value;
      }
      // 获取到指定标签
    },

    // 确定按钮
    async confirm() {
      console.log(this.data);
      if (this.data.amount) {
        try {
          const res = await axios.post(
            "https://pay.intellijocean.cn/staticCodePay/api/static/staticQrCodeSubmit",
            this.data
          );
          console.log(res);
          window.location.href = res.data.data;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$message.info("请填写金额");
        console.log(this.data.amount);
      }
    },

    keyUp() {
      this.flag = false;
      document.removeEventListener("mouseup", this.keyUp);
      document.removeEventListener("mouseleave", this.keyUp);
    },
    keyDown(e) {
      this.downInfo.x = e.pageX;
      this.downInfo.y = e.pageY;
      this.downInfo.left = this.moveX;
      this.downInfo.bottom = this.moveY;
      this.seto = setTimeout(() => {
        this.flag = true;
        document.addEventListener("mouseup", this.keyUp);
        document.addEventListener("mouseleave", this.keyUp);
        clearTimeout(this.seto);
      }, 1000);
    },
    keyMove(e) {
      if (this.flag) {
        const maxh =
          (document.clientHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) - this.$refs.keyboard.clientHeight;
        const htj = this.downInfo.bottom + this.downInfo.y - e.pageY;
        const maxw =
          (document.clientWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth) - this.$refs.keyboard.clientWidth;
        const wtj = this.downInfo.left - (this.downInfo.x - e.pageX);
        if (wtj <= maxw && wtj >= 0) {
          //确保键盘始终在屏幕可见范围内
          this.moveX = wtj;
        }
        if (htj <= maxh && htj >= 0) {
          //确保键盘始终在屏幕可见范围内
          this.moveY = htj;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.keyboard {
  width: 100vw;
  height: 260px;
  //   max-width: 353px;
  //   min-width: 285px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #d6d7db;
  > .drag {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > p {
      width: 30%;
      height: 13%;
      border-radius: 5px;
      background-color: #fff;
    }
    > div {
      width: 18.3%;
      height: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      &::before {
        width: 1px;
        content: "";
        height: 60%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
      > img {
        user-select: none;
        width: 45%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  > .main {
    height: calc(100% - 0%);
    display: flex;
    justify-content: space-between;
    padding: 2%;
    > .left {
      flex: 15;
      margin-right: 2%;
      height: 100%;
      border-radius: 6px;
      background-color: #fff;
      overflow: auto;
      user-select: none;
      &::-webkit-scrollbar {
        display: none;
      }

      > div {
        font-size: 20px;
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:active {
          background-color: #a1a8b8;
        }
      }
    }
    > .right {
      height: 100%;
      border-radius: 5px;
      flex: 77;
      display: flex;
      justify-content: space-between;
      user-select: none;
      > .left {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        width: 77%;
        > .item {
          background-color: #fff;
          height: 22%;
          width: 31%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          font-size: 18px;
          &:active {
            background-color: #adb4be;
          }
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          &:nth-of-type(7),
          &:nth-of-type(8),
          &:nth-of-type(9) {
            margin-bottom: 0;
          }
        }
      }
      > .right {
        width: 21%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .item {
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 22%;
          background-color: #fff;
          > img {
            width: 40%;
          }
          &:active {
            background-color: #7e8492;
          }
        }
      }
    }
  }
}
</style>
